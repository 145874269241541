.App{
    height: 100vh;
    color: black;
    display: flex;
    margin-top: 70px;
    align-items: center;
    flex-direction: column;
}

.Panel {
    background-color: #f0f0f0; 
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px; 
    border: 0px; 
    border-radius: 15px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .ContainerInput {
    margin-top: 20px;
    display: flex;
    flex-direction: column; /* Altere para column para colocar os elementos um abaixo do outro */
    align-items: center;
    text-align: center;
  }

.ContainerInput input{
    margin-bottom: 10px; 
}
.ContainerInput button{
    margin-top: 20px; 
    color: black;
    background-color: #FFE30D;
    font-size: 15px;
    width: 100px;
    height: 30px;
}

